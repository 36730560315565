.box-login {
  background-color: $white;
  padding: 20px;
  border-radius: 12px;
  min-width: 528px;
  min-height: 200px;
}

.label-default {
  font-size: 12px;
  font-weight: 600;
  margin-bottom: 5px;
  color: $neutral-black;
}