$positions: relative, absolute, fixed, inherit, sticky;

.w-full {
  width: 100%;
}

.wv-full {
  width: 100vw;
}

.w-half {
  width: 50%;
}

.w-75 {
  width: 75%;
}

.h-full {
  height: 100%;
}

.hv-full {
  height: 100vh;
}

.h-half {
  height: 50%;
}

@for $i from 1 through 100 {
  .row-gap-#{$i} {
    row-gap: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .column-gap-#{$i} {
    column-gap: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .my-#{$i} {
    margin-top: #{$i}px;
    margin-bottom: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .mx-#{$i} {
    margin-left: #{$i}px;
    margin-right: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .mt-#{$i} {
    margin-top: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .ml-#{$i} {
    margin-left: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .mr-#{$i} {
    margin-right: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .mb-#{$i} {
    margin-bottom: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .p-#{$i} {
    padding: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .py-#{$i} {
    padding-top: #{$i}px;
    padding-bottom: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .px-#{$i} {
    padding-left: #{$i}px;
    padding-right: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .pt-#{$i} {
    padding-top: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .pl-#{$i} {
    padding-left: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .pr-#{$i} {
    padding-right: #{$i}px;
  }
}

@for $i from 1 through 100 {
  .pb-#{$i} {
    padding-bottom: #{$i}px;
  }
}

@each $position in $positions {
  .position-#{$position} {
    position: $position;
  }
}
