@import "breakpoints";

@for $i from 1 through 15 {
  .br-#{$i} {
    border-radius: #{$i}px;
  }
}

.underline {
  text-decoration: underline;
}

.transition-ease {
  transition: all 0.3s ease;
}

.line {
  width: 100%;
  height: 1px;
  background-color: $neutral-disabled-1;
}

.divisor {
  width: 100%;
  border-top: 1px solid $neutral-input-1;
}

.user-none {
  user-select: none;
}

.mat-tooltip {
  font-size: 12px;
  font-family: "Montserrat", sans-serif;
}

.pointer {
  cursor: pointer;
}

.disc {
  padding-left: 15px;
  list-style-type: disc;
}

@media (max-width: $breakpoint-xl) {
  .break-wrap {
    justify-content: center;
    flex-wrap: wrap;

    .flex-1 {
      flex: 0 0 100% !important;
    }
  }
}