.mdc-dialog__surface {

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border-radius: 20px;
  }
}

.user-dialog {
  width: 550px;
  max-height: 95vh;
}

.customer-dialog {
  width: 550px;
  height: 500px;
}

.confirmation-dialog {
  width: 550px;
}

.profile-dialog {
  width: 550px;
  height: 360px;
}

.custom-dialog {
  min-width: 400px !important;
  max-width: 550px !important;
}

.informative-dialog {
  width: 600px;
  max-height: 85vh;
}

.stakeholder-dialog {
  width: 550px;
  max-height: 95vh;
}